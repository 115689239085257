
























































































































































































































import { defineComponent } from "@nuxtjs/composition-api";
import { SfButton } from "@storefront-ui/vue";
import CustomBlock from "~/components/CustomBlock.vue";
import CustomBlocks from "~/components/CustomBlocks.vue";
import CopyRight from "~/components/Footer/CopyRight.vue";
import IconArrowRt from "~/components/General/IconArrowRt.vue";
import IconMinus from "~/components/General/IconMinus.vue";
import IconPinAddress from "~/components/General/IconPinAddress.vue";
import IconPlus from "~/components/General/IconPlus.vue";
import HeaderLogo from "~/components/HeaderLogo.vue";
import Preload from "~/components/Preload.vue";

export default defineComponent({
  components: {
    SfButton,
    CustomBlocks,
    HeaderLogo,
    IconPinAddress,
    CopyRight,
    IconArrowRt,
    IconPlus,
    IconMinus,
    CustomBlock,
    Preload,
    StoreSwitcher: () => import("~/components/StoreSwitcher.vue"),
  },
  setup() {
    const companyInfo = {
      name: "บริษัท ยู เทค อินโนเวชัน จำกัด",
      address_1: "343/37-38 ถนนคลองลำเจียก แขวงนวลจันทร์",
      address_2: "เขตบึงกุ่ม กรุงเทพมหานคร 10240",
    };

    const socialLinks = [
      {
        name: "facebook",
        link: "https://www.facebook.com/utechTH",
        icon: "/images/social/facebook-logo.webp",
      },
      {
        name: "twitter",
        link: "#",
        icon: "/images/social/twitter-logo.webp",
      },
      {
        name: "instagram",
        link: "#",
        icon: "/images/social/instagram-logo.webp",
      },
      {
        name: "youtube",
        link: "https://www.youtube.com/channel/UC5xsAMqQgBoiaFhwF7oKVYg",
        icon: "/images/social/youtube-logo.webp",
      },
      {
        name: "mail",
        link: "#",
        icon: "/images/social/mail-logo.webp",
      },
    ];

    return {
      companyInfo,
      socialLinks,
    };
  },
  methods: {
    ftdropdown(section) {
      if (section === "menu") {
        this.isActiveMenu = !this.isActiveMenu;
      } else if (section === "service") {
        this.isActiveService = !this.isActiveService;
      } else if (section === "support") {
        this.isActiveSupport = !this.isActiveSupport;
      }
    },
  },
  data() {
    return {
      isActiveMenu: false,
      isActiveService: false,
      isActiveSupport: false,
    };
  },
});
